<template lang="pug">
  .row
    .form-group.col
      label(for="from") {{ 'fromm' | translate }}
      datetime(v-model="start_date",
              :requiresTime="requiresTime",
              :max-datetime="end_date",
              :disabled="disabled",
              refName="start_date", input-id="start_date")
    .form-group.col
      label(for="to") {{ 'to' | translate }}
      datetime(v-model="end_date",
              :requiresTime="requiresTime",
              :min-datetime="start_date",
              :disabled="disabled",
              refName="end_date", input-id="end_date")
</template>

<script>
import Datetime from "~/components/common/Datetime/Datetime.vue";
import { mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  props: {
    requiresTime: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Datetime,
  },
  mounted() {
    if (this.requiresTime) this.changeTypeToDatetime();
  },
  methods: mapMutations("datetime", ["changeTypeToDatetime"]),
  computed: mapFields("datetime", ["start_date", "end_date"]),
};
</script>
