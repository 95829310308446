import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/activity.js'), 'activity.js')
  resolveStoreModules(require('../store/activityLogs.js'), 'activityLogs.js')
  resolveStoreModules(require('../store/alert.js'), 'alert.js')
  resolveStoreModules(require('../store/alertsList.js'), 'alertsList.js')
  resolveStoreModules(require('../store/assignTemplates.js'), 'assignTemplates.js')
  resolveStoreModules(require('../store/atlas.js'), 'atlas.js')
  resolveStoreModules(require('../store/auditExpansions.js'), 'auditExpansions.js')
  resolveStoreModules(require('../store/backsheets.js'), 'backsheets.js')
  resolveStoreModules(require('../store/barcodeVerificationForm.js'), 'barcodeVerificationForm.js')
  resolveStoreModules(require('../store/barcodeVerificationResultsShow.js'), 'barcodeVerificationResultsShow.js')
  resolveStoreModules(require('../store/binSearch.js'), 'binSearch.js')
  resolveStoreModules(require('../store/cameraSettings.js'), 'cameraSettings.js')
  resolveStoreModules(require('../store/changes.js'), 'changes.js')
  resolveStoreModules(require('../store/changeWorkItemFieldMask.js'), 'changeWorkItemFieldMask.js')
  resolveStoreModules(require('../store/comments.js'), 'comments.js')
  resolveStoreModules(require('../store/contentWithHeader.js'), 'contentWithHeader.js')
  resolveStoreModules(require('../store/controlChart.js'), 'controlChart.js')
  resolveStoreModules(require('../store/correctiveActionPlans.js'), 'correctiveActionPlans.js')
  resolveStoreModules(require('../store/correctiveActionsSearch.js'), 'correctiveActionsSearch.js')
  resolveStoreModules(require('../store/createLabelConstraints.js'), 'createLabelConstraints.js')
  resolveStoreModules(require('../store/customEmbeds.js'), 'customEmbeds.js')
  resolveStoreModules(require('../store/dashboard.js'), 'dashboard.js')
  resolveStoreModules(require('../store/dashboardPanels.js'), 'dashboardPanels.js')
  resolveStoreModules(require('../store/dataLogs.js'), 'dataLogs.js')
  resolveStoreModules(require('../store/datetime.js'), 'datetime.js')
  resolveStoreModules(require('../store/defect.js'), 'defect.js')
  resolveStoreModules(require('../store/departments.js'), 'departments.js')
  resolveStoreModules(require('../store/design.js'), 'design.js')
  resolveStoreModules(require('../store/deviceDashboard.js'), 'deviceDashboard.js')
  resolveStoreModules(require('../store/devices.js'), 'devices.js')
  resolveStoreModules(require('../store/devicesTest.js'), 'devicesTest.js')
  resolveStoreModules(require('../store/documents.js'), 'documents.js')
  resolveStoreModules(require('../store/downtime.js'), 'downtime.js')
  resolveStoreModules(require('../store/editScheduledQuestionnaire.js'), 'editScheduledQuestionnaire.js')
  resolveStoreModules(require('../store/errorMethods.js'), 'errorMethods.js')
  resolveStoreModules(require('../store/events.js'), 'events.js')
  resolveStoreModules(require('../store/exams.js'), 'exams.js')
  resolveStoreModules(require('../store/expansionForm.js'), 'expansionForm.js')
  resolveStoreModules(require('../store/failuresEdit.js'), 'failuresEdit.js')
  resolveStoreModules(require('../store/fieldMasksForm.js'), 'fieldMasksForm.js')
  resolveStoreModules(require('../store/fieldMasksIndex.js'), 'fieldMasksIndex.js')
  resolveStoreModules(require('../store/header.js'), 'header.js')
  resolveStoreModules(require('../store/heatmapAnswersSearch.js'), 'heatmapAnswersSearch.js')
  resolveStoreModules(require('../store/ichartSearch.js'), 'ichartSearch.js')
  resolveStoreModules(require('../store/importConstraints.js'), 'importConstraints.js')
  resolveStoreModules(require('../store/indexForCustomer.js'), 'indexForCustomer.js')
  resolveStoreModules(require('../store/job.js'), 'job.js')
  resolveStoreModules(require('../store/jobForm.js'), 'jobForm.js')
  resolveStoreModules(require('../store/jobQuestionnaires.js'), 'jobQuestionnaires.js')
  resolveStoreModules(require('../store/jobRecipients.js'), 'jobRecipients.js')
  resolveStoreModules(require('../store/jobRevision.js'), 'jobRevision.js')
  resolveStoreModules(require('../store/jobRevisionForm.js'), 'jobRevisionForm.js')
  resolveStoreModules(require('../store/jobRevisionSuspectCodeModels.js'), 'jobRevisionSuspectCodeModels.js')
  resolveStoreModules(require('../store/languages.js'), 'languages.js')
  resolveStoreModules(require('../store/leftEarlyReasons.js'), 'leftEarlyReasons.js')
  resolveStoreModules(require('../store/libraries.js'), 'libraries.js')
  resolveStoreModules(require('../store/licenceLogs.js'), 'licenceLogs.js')
  resolveStoreModules(require('../store/login.js'), 'login.js')
  resolveStoreModules(require('../store/ndas.js'), 'ndas.js')
  resolveStoreModules(require('../store/newObservationCorrection.js'), 'newObservationCorrection.js')
  resolveStoreModules(require('../store/notifications.js'), 'notifications.js')
  resolveStoreModules(require('../store/payableTimeSummary.js'), 'payableTimeSummary.js')
  resolveStoreModules(require('../store/questionnaireEdit.js'), 'questionnaireEdit.js')
  resolveStoreModules(require('../store/questionnaireGroups.js'), 'questionnaireGroups.js')
  resolveStoreModules(require('../store/questionnaireImporter.js'), 'questionnaireImporter.js')
  resolveStoreModules(require('../store/questionnaireResults.js'), 'questionnaireResults.js')
  resolveStoreModules(require('../store/questionnaireResultsShow.js'), 'questionnaireResultsShow.js')
  resolveStoreModules(require('../store/questionnaires.js'), 'questionnaires.js')
  resolveStoreModules(require('../store/questionnairesNew.js'), 'questionnairesNew.js')
  resolveStoreModules(require('../store/questionnaireStatsSearch.js'), 'questionnaireStatsSearch.js')
  resolveStoreModules(require('../store/questionsNew.js'), 'questionsNew.js')
  resolveStoreModules(require('../store/queueLogs.js'), 'queueLogs.js')
  resolveStoreModules(require('../store/referencePictures.js'), 'referencePictures.js')
  resolveStoreModules(require('../store/rejectStatsSearch.js'), 'rejectStatsSearch.js')
  resolveStoreModules(require('../store/rejectSummaries.js'), 'rejectSummaries.js')
  resolveStoreModules(require('../store/reportStatuses.js'), 'reportStatuses.js')
  resolveStoreModules(require('../store/revisionTrainingResults.js'), 'revisionTrainingResults.js')
  resolveStoreModules(require('../store/roles.js'), 'roles.js')
  resolveStoreModules(require('../store/rolesTranslations.js'), 'rolesTranslations.js')
  resolveStoreModules(require('../store/scheduled.js'), 'scheduled.js')
  resolveStoreModules(require('../store/scheduledQuestionnaireNotifications.js'), 'scheduledQuestionnaireNotifications.js')
  resolveStoreModules(require('../store/selects.js'), 'selects.js')
  resolveStoreModules(require('../store/severities.js'), 'severities.js')
  resolveStoreModules(require('../store/shiftDescriptions.js'), 'shiftDescriptions.js')
  resolveStoreModules(require('../store/shiftForm.js'), 'shiftForm.js')
  resolveStoreModules(require('../store/shifts.js'), 'shifts.js')
  resolveStoreModules(require('../store/shiftsByDate.js'), 'shiftsByDate.js')
  resolveStoreModules(require('../store/showLicense.js'), 'showLicense.js')
  resolveStoreModules(require('../store/stats.js'), 'stats.js')
  resolveStoreModules(require('../store/summaryReportModule.js'), 'summaryReportModule.js')
  resolveStoreModules(require('../store/suspectCodeModelList.js'), 'suspectCodeModelList.js')
  resolveStoreModules(require('../store/suspectStatsSearch.js'), 'suspectStatsSearch.js')
  resolveStoreModules(require('../store/tabletRoles.js'), 'tabletRoles.js')
  resolveStoreModules(require('../store/tags.js'), 'tags.js')
  resolveStoreModules(require('../store/teams.js'), 'teams.js')
  resolveStoreModules(require('../store/timesheets.js'), 'timesheets.js')
  resolveStoreModules(require('../store/unassignedLicences.js'), 'unassignedLicences.js')
  resolveStoreModules(require('../store/users.js'), 'users.js')
  resolveStoreModules(require('../store/verificationWorkItems.js'), 'verificationWorkItems.js')
  resolveStoreModules(require('../store/videos.js'), 'videos.js')
  resolveStoreModules(require('../store/workAreas.js'), 'workAreas.js')
  resolveStoreModules(require('../store/xhrRequests.js'), 'xhrRequests.js')
  resolveStoreModules(require('../store/activityLogs/forDevice.js'), 'activityLogs/forDevice.js')
  resolveStoreModules(require('../store/activityLogs/forUser.js'), 'activityLogs/forUser.js')
  resolveStoreModules(require('../store/bins/binForm.js'), 'bins/binForm.js')
  resolveStoreModules(require('../store/customers/customerForm.js'), 'customers/customerForm.js')
  resolveStoreModules(require('../store/dashboard/editGauge.js'), 'dashboard/editGauge.js')
  resolveStoreModules(require('../store/dashboard/editPanel.js'), 'dashboard/editPanel.js')
  resolveStoreModules(require('../store/dashboard/gaugeDefinitions.js'), 'dashboard/gaugeDefinitions.js')
  resolveStoreModules(require('../store/dashboard/my.js'), 'dashboard/my.js')
  resolveStoreModules(require('../store/deviceDashboard/assistance.js'), 'deviceDashboard/assistance.js')
  resolveStoreModules(require('../store/deviceDashboard/controlChart.js'), 'deviceDashboard/controlChart.js')
  resolveStoreModules(require('../store/deviceDashboard/embedded.js'), 'deviceDashboard/embedded.js')
  resolveStoreModules(require('../store/deviceDashboard/questionnaireResults.js'), 'deviceDashboard/questionnaireResults.js')
  resolveStoreModules(require('../store/deviceDashboard/questionnaires.js'), 'deviceDashboard/questionnaires.js')
  resolveStoreModules(require('../store/deviceDashboard/result.js'), 'deviceDashboard/result.js')
  resolveStoreModules(require('../store/deviceDashboard/stats.js'), 'deviceDashboard/stats.js')
  resolveStoreModules(require('../store/deviceDashboard/verifications.js'), 'deviceDashboard/verifications.js')
  resolveStoreModules(require('../store/questions/cameraCapture.js'), 'questions/cameraCapture.js')
  resolveStoreModules(require('../store/questions/controlChart.js'), 'questions/controlChart.js')
  resolveStoreModules(require('../store/questions/editQuestions.js'), 'questions/editQuestions.js')
  resolveStoreModules(require('../store/questions/heatmap.js'), 'questions/heatmap.js')
  resolveStoreModules(require('../store/questions/inspectorSignature.js'), 'questions/inspectorSignature.js')
  resolveStoreModules(require('../store/questions/multiSelect.js'), 'questions/multiSelect.js')
  resolveStoreModules(require('../store/questions/passFail.js'), 'questions/passFail.js')
  resolveStoreModules(require('../store/questions/scorePassFail.js'), 'questions/scorePassFail.js')
  resolveStoreModules(require('../store/questions/severity.js'), 'questions/severity.js')
  resolveStoreModules(require('../store/questions/tolerance.js'), 'questions/tolerance.js')
  resolveStoreModules(require('../store/questions/valueCompare.js'), 'questions/valueCompare.js')
  resolveStoreModules(require('../store/suspectCodeModelList/assignJobs.js'), 'suspectCodeModelList/assignJobs.js')
  resolveStoreModules(require('../store/suspectCodeModelList/editSuspectCodeModel.js'), 'suspectCodeModelList/editSuspectCodeModel.js')
  resolveStoreModules(require('../store/training/examResult.js'), 'training/examResult.js')
  resolveStoreModules(require('../store/training/examResults.js'), 'training/examResults.js')
  resolveStoreModules(require('../store/training/experience.js'), 'training/experience.js')
  resolveStoreModules(require('../store/users/userForm.js'), 'users/userForm.js')
  resolveStoreModules(require('../store/verification_work_items/editVerificationWorkItem.js'), 'verification_work_items/editVerificationWorkItem.js')
  resolveStoreModules(require('../store/verification_work_items/import.js'), 'verification_work_items/import.js')
  resolveStoreModules(require('../store/permutation/audit/questionnaireConfigurationForm.js'), 'permutation/audit/questionnaireConfigurationForm.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
