<template lang="pug">
  .footer.text-center
    .d-flex.justify-content-center
      a(:href='supportUrl', target="_blank")
        h4 {{ 'contact_us' |  translate }}
    p.mb-0.text-muted {{ 'patent_pending' | translate }}
    p.mb-0.text-muted {{ 'copyright' | translate }}
    p.mb-0.text-muted {{ 'copyright2' | translate }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters("login", ["supportUrl"])
};
</script>


<style lang="scss" scoped>
@import "~/assets/stylesheets/colors.scss";

.footer {
  margin-top: auto;
}
</style>
