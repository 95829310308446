import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { translate } from '~/assets/javascript/localization/Localization';
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  questionStore: '',
  questionControllerName: '',
  questionHeaderName: '',
  questionType: '',
  id: null,
  suspectOnFail: null,
  isAnswerFailure: null,
  defectQuestionnaires: [],
  appliedChampionTemplate: {},
  availableChampionTemplates: [],
  allowableChampionTemplatesDetails: [],
  allowableSeverities: [],
  allowableRoles: [],
  description: null,
  descriptionShorthand: null,
  associatedToBin: false,
  canOptOut: false,
  allowMultipleAnswer: false,
  hasResultOverride: false,
  autoVerifiedOnResolve: false,
  unitOfMeasure: "",
  passImagePreview: null,
  failImagePreview: null,
  questionReferenceImagePreview: null,
  valueLabel: '',
  passImage: {},
  failImage: {},
  questionReferenceImage: {},
  passLabel: "Pass",
  failLabel: "Fail",
  warnLabel: "Warn",
  optOutLabel: "Opt-Out",
  notApplicableLabel: "N/A",
  notificationMessage: "",
  requiresCorrectiveActionOnFailure: false,
  lockSuspectCode: false,
  allowSuspectCodesCreation: false,
  requireDefectImage: false,
  useHeatmapOnFailure: false,
  allowImageUploadFromLibrary: false,
  championTemplateId: null,
  passValue: null,
  failValue: null,
  warnValue: null,
  questionnaireId: null,
  defectCodeModelId: null,
  suspectCode: null,
  failureQuestionnaireId: null,
  minimumObservations: 1,
  breadCrumbsData: null,
  useWeightAsScore: false,
  notApplicable: false,
  commentRequired: false,
  frequency: null,
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setQuestionControllerAndType(state, question) {
    state.questionControllerName = question
    state.questionType = `${question}s`
  },
  setHeaderName(state, question) {
    state.questionHeaderName = `${QuestionsHelper.convertToSnakeCase(question)}_question`
  },
  setQuestionStore(state, value) {
    state.questionStore = value
  },
  setCommonStateFromResponse(state, response) {
    QuestionsHelper.setState(state, response)
  },
  setPreviewImage(state, { preview, dataUrl }) {
    state[preview] = dataUrl;
  },
  setSuspectOnFailTo(state, value) {
    state.suspectOnFail = value
  },
  setisAnswerFailureTo(state, value) {
    state.isAnswerFailure = value
  },
  attachImage(state, { file, imageType }) {
    Vue.set(state[imageType], 'picture', file);
    Vue.set(state[imageType], "_destroy", false);
  },
  setImagesDeleteKeyToFalse(state) {
    Vue.set(state.passImage, "_destroy", false);
    Vue.set(state.failImage, "_destroy", false);
    Vue.set(state.questionReferenceImage, "_destroy", false);
  },
  setImageDeleteKeyTo(state, { image, value }) {
    Vue.set(state[image], "_destroy", value);
  },
  deletePicture(state, image) {
    state[`${image}Preview`] = null
    if (state[image]["picture"]) delete state[image]["picture"];

  },
  setWeightAttributesOn(state, params) {
    params["weight_attributes"] = {
      pass_value: state.passValue,
      fail_value: state.failValue,
      warn_value: state.warnValue
    }
  },
  setUpImagesToBeAttachedOrDeleted(state, params) {
    if (state.passImage.picture) params['passImage'] = state.passImage.picture
    else if (state.passImage._destroy) params['passImage'] = ""

    if (state.failImage.picture) params['failImage'] = state.failImage.picture
    else if (state.failImage._destroy) params['failImage'] = ""

    if (state.questionReferenceImage.picture) params['questionReferenceImage'] = state.questionReferenceImage.picture
    else if (state.questionReferenceImage._destroy) params['questionReferenceImage'] = ""
  },
  setAppliedChampionTemplateAttributes(state, params) {
    params['applied_champion_template_attributes'] = {
      champion_id: state.championTemplateId
    };
    let appliedChampionTemplate = state.appliedChampionTemplate.applied_champion
    if (appliedChampionTemplate) params['applied_champion_template_attributes'].id = appliedChampionTemplate.id
  },
  setDefaultLabelsIfNull(state) {
    let labels = [
      { name: 'passLabel', value: "pass" },
      { name: 'failLabel', value: "fail" },
      { name: 'warnLabel', value: "warn" },
      { name: 'optOutLabel', value: "opt_out" },
      { name: 'notApplicableLabel', value: "na" }
    ]
    labels.forEach(l => {
      if (state[l.name] == null) state[l.name] = translate(l.value)
    })
  },
  updateField
}

export const getters = {
  getField,
  persisted(state) {
    return state.id != null;
  },
  suspectSettingsTitle: (state) => (isForHeatmapQuestion) => {
    var title = ""
    if (isForHeatmapQuestion) {
      title = translate('failure_settings')
    } else {
      title = translate('defect_settings')
    }

    return title;
  },
  appliedChampionTemplateSet(state) {
    return Object.keys(state.appliedChampionTemplate).length > 0
  },
  editChampionPath(state) {
    return `/template/audit/corrective_action/champions/${state.championTemplateId}`
  },
  selectedTemplate(state) {
    return state.allowableChampionTemplatesDetails.find(t => {
      return state.championTemplateId === t.champion.id
    })
  },
  selectedDepartment(state) {
    var departmentName = translate('please_select')
    state.availableChampionTemplates.forEach(department => {
      department["champions"].forEach(c => {
        if (state["championTemplateId"] === c["id"]) departmentName = department["name"]
      })
    })
    return departmentName
  },
  selectsParams(state) {
    return {
      params: {
        defect_code_model_ids: state.defectCodeModelId,
        suspect_codes: state.suspectCode
      }
    }
  },
  questionIndexUrl(state) {
    return `/audit/questionnaires/${state.questionnaireId}/edit/questions`
  },
  breadCrumbs(state) {
    if (state.breadCrumbsData) {
      let data = state.breadCrumbsData
      return [
        { text: translate('questionnaire_groups'), path: '/audit/questionnaire_groups' },
        { text: data['group_name'], path: `/audit/questionnaire_groups/${data['group_id']}/questionnaires` },
        { text: data['questionnaire_name'], path: `/audit/questionnaires/${state.questionnaireId}/edit/questions` },
        { text: translate('question_details') }
      ]
    }
  }
}

export const actions = {
  async init({ state, commit, dispatch }, { params, questionControllerName, name }) {
    commit("setQuestionControllerAndType", questionControllerName)
    commit("setHeaderName", name)
    commit('setQuestionStore', name)
    const getUrl = QuestionsHelper.getUrl(params, state.questionType)

    AxiosWrapper.get(getUrl).then(({ data }) => {
      const store = state.questionStore
      commit(`questions/${store}/setStateFromResponse`, data, { root: true })
      commit('setCommonStateFromResponse', data)
      dispatch(`questions/${store}/configureInitState`, null, { root: true })
    })
  },
  async setFailureWorkflowIfWorkItem({ state, commit }, setIsAnswerFailure = false) {
    if (state.associatedToBin) {
      if (state.suspectOnFail === null && state.isAnswerFailure === null) {
        commit('setSuspectOnFailTo', true)
        if (setIsAnswerFailure) commit('setisAnswerFailureTo', false)
      }
    }
  },
  async setPreviewImages({ state, commit }) {
    if (Object.keys(state.passImage).length !== 0) commit("setPreviewImage", { preview: "passImagePreview", dataUrl: state.passImage.medium.url })

    if (Object.keys(state.failImage).length !== 0) commit("setPreviewImage", { preview: "failImagePreview", dataUrl: state.failImage.medium.url })

    if (Object.keys(state.questionReferenceImage).length !== 0) commit("setPreviewImage", { preview: "questionReferenceImagePreview", dataUrl: state.questionReferenceImage.medium.url })

    commit('setImagesDeleteKeyToFalse')
  },
  async setDefaultLabelsIfNull({ commit }) {
    commit("setDefaultLabelsIfNull")
  },
  async imageAttached({ dispatch }, { e, buttonName }) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    dispatch('attachImageToQuestion', { file: files[0], buttonName })
  },
  async attachImageToQuestion({ commit }, { file, buttonName }) {
    let reader = new FileReader();

    reader.onload = e => {
      const dataUrl = e.target.result;

      if (buttonName === "passButton") {
        commit("setPreviewImage", { preview: "passImagePreview", dataUrl })
      } else if (buttonName === "failButton") {
        commit("setPreviewImage", { preview: "failImagePreview", dataUrl })
      } else {
        commit("setPreviewImage", { preview: "questionReferenceImagePreview", dataUrl })
      }
    };

    reader.readAsDataURL(file);

    if (buttonName === "passButton") {
      commit('attachImage', { file, imageType: "passImage" })
    } else if (buttonName === "failButton") {
      commit('attachImage', { file, imageType: "failImage" })
    } else {
      commit('attachImage', { file, imageType: "questionReferenceImage" })
    }
  },
  async removePassImage({ dispatch }) {
    dispatch('removeImage', 'passImage')
  },
  async removeFailImage({ dispatch }) {
    dispatch('removeImage', 'failImage')
  },
  async removeQuestionReferenceImage({ dispatch }) {
    dispatch('removeImage', 'questionReferenceImage')
  },
  async removeImage({ commit }, image) {
    commit('setImageDeleteKeyTo', { image, value: true })
    commit('deletePicture', image)
  },
  async configueCommonInit({ dispatch, getters }, initSelects = false) {
    if (initSelects) dispatch("selects/init", getters['selectsParams'], { root: true })
    dispatch('setFailureWorkflowIfWorkItem', true)
    dispatch('setPreviewImages')
    dispatch('setDefaultLabelsIfNull')
  },
  async configureCommonParams({ commit }, params) {
    commit("setWeightAttributesOn", params)
    commit("setUpImagesToBeAttachedOrDeleted", params)
    commit("setAppliedChampionTemplateAttributes", params)
  },
  async saveButtonPressed({ state, dispatch, rootState }) {
    const store = state.questionStore
    const url = `/data_api/audit/questionnaires/${state.questionnaireId}/${state.questionType}`

    let questionParams = {
      ...state,
      ...rootState['questions'][store],
      defect_code_model_id: rootState.selects.params.defect_code_model_ids,
      suspect_code: rootState.selects.params.suspect_codes,
    }

    if (rootState.selects.selectedSuspectCodes) {
      questionParams["defect_code_id"] = rootState.selects.selectedSuspectCodes.value
    } else {
      questionParams["defect_code_id"] = null
    }

    dispatch(`questions/${store}/configureParams`, questionParams, { root: true })

    let params = {}

    params[state.questionControllerName] = QuestionsHelper.convertAllKeysToSnakeCase(questionParams)
    params["questionnaire_id"] = state.questionnaireId

    return AxiosWrapper.postOrPatch(url, params)
  },
  async initQuestionNoStore({ commit }, { params, question, name }) {
    commit("setQuestionControllerAndType", question)
    commit("setHeaderName", name)
    const getUrl = QuestionsHelper.getUrl(params, `${question}s`)

    return AxiosWrapper.get(getUrl).then(({ data }) => {
      commit('setCommonStateFromResponse', data)
    })
  },
  async saveQuestionNoStore({ dispatch, state, rootState }) {
    const url = `/data_api/audit/questionnaires/${state.questionnaireId}/${state.questionType}`
    let questionsParams = {
      ...state,
      defect_code_model_id: rootState.selects.params.defect_code_model_ids,
      suspect_code: rootState.selects.params.suspect_codes
    }
    let params = {}

    dispatch("configureCommonParams", questionsParams)

    params[state.questionControllerName] = QuestionsHelper.convertAllKeysToSnakeCase(questionsParams)
    params["questionnaire_id"] = state.questionnaireId

    return AxiosWrapper.postOrPatch(url, params)
  }
}