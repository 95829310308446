import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';
import gtmEvents from "~/assets/javascript/gtmEvents"

AxiosWrapper.init();

const getDefaultState = () => ({
  storeApiUrl: "",
  current_job_id: null,
  confirmingCsvRequest: false,
  preselectedJobRevision: null,
  device_id: null,
  questionnairegroups: [],
  questionnaires: [],
  selectedinstruction: [],
  instructions: [],
  part_numbers: [],
  shifts: [],
  job_revisions: [],
  searchInProgress: false,
  firstLoad: false,
  control_chart_object: null,
  current_user: null,
  options: null,
  showCorrection: true,
  csvDownloadUrl: null,
  params: {
    start_date: "",
    end_date: "",
    instruction: {},
    part_number: {},
    shift: {},
    job_revision_id: {},
    use_unbiasing_constant: true,
    subgroup_size: 5,
    editing_correction_id: null
  },
  hasSearched: false
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  initDataFromGet(state, getRequestData) {
    ({
      subgroup_size: state.subgroup_size,
      control_chart_object: state.control_chart_object,
      current_user: state.current_user,
      options: state.options,
      show_correction: state.show_correction,
      current_job_id: state.current_job_id
    } = getRequestData);
  },
  initLabelOptions(state, getRequestData) {
    let instructions = []
    let partNumbers = []
    let shiftItems = []
    let jobRevisions = []

    // if (getRequestData.instructions) {
    //   instructions = getRequestData.instructions.map((value, index) => {
    //     return value
    //   })
    // }

    // state.instructions = [{ name: translate('select_all'), value: null }].concat(
    //   instructions
    // )

    if (getRequestData.part_numbers) {
      partNumbers = getRequestData.part_numbers.map((value, index) => {
        return value;
      })
    }

    state.part_numbers = [{ name: translate('select_part_number'), value: null }].concat(
      partNumbers
    )

    if (getRequestData.shifts) {
      shiftItems = getRequestData.shifts.map((value, index) => {
        return value;
      })
    }

    state.shifts = [{ name: translate('select_shift'), value: null }].concat(
      shiftItems
    )

    if (getRequestData.job_revisions) {
      jobRevisions = getRequestData.job_revisions.map((value, index) => {
        return value;
      })
    }

    state.job_revisions = [{ name: translate('all_job_revisions'), value: null }].concat(
      jobRevisions
    )
  },
  selectFirstConstraints(state) {
    //state.params.instruction = state.instruction[0]
    state.params.instruction = null
    state.params.part_number = state.part_numbers[0]
    state.params.shift = state.shifts[0]
    state.params.job_revision_id = state.job_revisions[0]
  },
  drawChartsWithResponse(state, getRequestData) {
    ({
      control_chart_object: state.control_chart_object,
      options: state.options,
    } = getRequestData);

    state.searchInProgress = false
  },
  setDownloadUrl(state, params) {
    let job = AxiosWrapper.getNextPathNameElement('jobs')
    let jobRevision = AxiosWrapper.getNextPathNameElement('job_revisions')

    if (job) {
      params['job_id'] = job
    }

    if (jobRevision) {
      params['job_revision_id'] = jobRevision
    }

    state.csvDownloadUrl = `/data_api/download_csv/control_chart${AxiosWrapper.buildSearchUrl(params)}`
  },
  setQuestionnairegroups(state, questionnairegroups) {
    state.questionnairegroups = questionnairegroups
  },
  setQuestionnaires(state, questionnaires) {
    state.questionnaires = questionnaires;
  },
  setInstructions(state, instructions) {
    state.instructions = instructions;
  },
}

export const getters = {
  getField,
}

export const actions = {
  async init({ state, commit, dispatch }, context) {
    gtmEvents.reportGenerated({
      type: "Control Charts",
      context
    })
    commit("resetState")

    commit('updateField', { path: 'firstLoad', value: true })
    let job = AxiosWrapper.getNextPathNameElement('jobs')
    let jobRevision = AxiosWrapper.getNextPathNameElement('job_revisions')

    if (state.current_job_id === null) {
      commit('updateField', { path: 'current_job_id', value: job })
    }

    let loadedParams = AxiosWrapper.getSearchParams();
    let searchingParams = AxiosWrapper.flattenObject(loadedParams)
    searchingParams = AxiosWrapper.copyAndDeleteNullProperties(searchingParams)

    if (loadedParams['device']) {
      commit('updateField', { path: 'device_id', value: loadedParams.device })
    }

    if (state.storeApiUrl === "") {
      if (jobRevision) {
        commit('updateField', { path: 'storeApiUrl', value: `/data_api/jobs/${job}/job_revisions/${jobRevision}/checklist/control_chart_instructions/instruction_search` })
      } else {
        commit('updateField', { path: 'storeApiUrl', value: `/data_api/jobs/${state.current_job_id}/control_charts` })
      }
    }
    dispatch("datetime/init", { params: searchingParams, isReportPage: true }, { root: true });
    await AxiosWrapper.get(state.storeApiUrl, { params: searchingParams })
      .then(function (response) {
        let pathWithParams = AxiosWrapper.buildPathWithSearchParams(searchingParams);
        history.replaceState(history.state, 'ControlChartSearch', pathWithParams);
        commit('setDownloadUrl', searchingParams)
        commit('initDataFromGet', response.data)
        commit('initLabelOptions', response.data)
        if (response.data.questionnaire_groups) {
          commit("setQuestionnairegroups", response.data.questionnaire_groups);
        }
        commit('selectFirstConstraints')
        dispatch('preselectRevision')
        dispatch('updateSearchSettings', response.data.search_params_used)
        commit('updateField', { path: 'firstLoad', value: false })

      })
  },
  preselectRevision({ commit, state }) {
    if (state.preselectedJobRevision) {
      commit('updateField', {
        path: 'params.job_revision_id', value: {
          name: `${state.preselectedJobRevision.formatted_revision_date} ${state.preselectedJobRevision.revision_number}`,
          value: state.preselectedJobRevision.id
        }
      })
    }
  },
  searchForControlChart({ state, commit, dispatch, rootGetters }, { router, route, context }) {
    commit('updateField', { path: 'control_chart_object', value: null })
    commit('updateField', { path: 'searchInProgress', value: true })

    gtmEvents.reportGenerated({
      type: "Control Charts",
      context
    })

    if (state.selectedinstruction[0] && state.selectedinstruction[0].name == "Select All") {
      const instructions = state.instructions.slice(1)
      commit('updateField', { path: `params.instruction`, value: instructions })
    } else {
      commit('updateField', { path: `params.instruction`, value: state.selectedinstruction })
    }

    let searchingParams = AxiosWrapper.flattenObject({ ...state.params, ...rootGetters['datetime/getStartAndEndDate'] })
    searchingParams = AxiosWrapper.copyAndDeleteNullProperties(searchingParams)

    AxiosWrapper.get(state.storeApiUrl, { params: searchingParams }).then(function (response) {
      commit('updateField', { path: 'hasSearched', value: true })
      router.replace({
        path: route.path,
        query: { ...(route.query), ...searchingParams }
      })
      commit('initLabelOptions', response.data)
      dispatch('updateSearchSettings', response.data.search_params_used)
      commit('drawChartsWithResponse', response.data)
      commit('setDownloadUrl', searchingParams)
    })
  },
  updatePathAfterDefault({ commit, state }) {
    commit('updateField', { path: 'path_updated', value: true })
    let searchingParams = AxiosWrapper.flattenObject(state.params)

    searchingParams = AxiosWrapper.copyAndDeleteNullProperties(searchingParams);
    var pathWithParams = AxiosWrapper.buildPathWithSearchParams(searchingParams);
    history.replaceState(history.state, 'ControlChartSearch', pathWithParams);
  },
  setRevisionDefault({ commit, state, dispatch }) {
    commit('updateField', { path: `params.job_revision`, value: state.available_job_revisions[0] })
    dispatch('updatePathAfterDefault');
  },
  setInstructionDefault({ commit, state, dispatch }) {
    //commit('updateField', { path: `params.instruction`, value: state.instructions[0] })
    dispatch('updatePathAfterDefault');
  },
  setPartDefault({ commit, state, dispatch }) {
    commit('updateField', { path: `params.part_number`, value: state.part_numbers[0] })
    dispatch('updatePathAfterDefault');
  },
  setShiftDefault({ commit, state, dispatch }) {
    commit('updateField', { path: `params.shift`, value: state.shifts[0] })
    dispatch('updatePathAfterDefault');
  },
  updateMultiSelect({ commit, state, dispatch }, { key, value }) {
    if (key === 'instruction') {
      let instructionindex = state.instructions.map(function (e) { return e.value; }).indexOf(value);
      if (instructionindex !== -1) {
        commit('updateField', { path: `params.instruction`, value: state.instructions[instructionindex] })
      } else {
        dispatch('setInstructionDefault');
      }
    }
    if (key === 'job_revision') {
      let revisionindex = state.job_revisions.map(function (e) { return e.value; }).indexOf(Number(value));
      if (revisionindex !== -1) {
        commit('updateField', { path: `params.job_revision_id`, value: state.job_revisions[revisionindex] })
      } else {
        dispatch('setRevisionDefault');
      }
    }
    if (key === 'part_number') {
      let partindex = state.part_numbers.map(function (e) { return e.value; }).indexOf(value);
      if (partindex !== -1) {
        commit('updateField', { path: `params.part_number`, value: state.part_numbers[partindex] })
      } else {
        dispatch('setPartDefault');
      }
    }
    if (key === 'shift') {
      let shiftindex = state.shifts.map(function (e) { return e.value; }).indexOf(Number(value));
      if (shiftindex !== -1) {
        commit('updateField', { path: `params.shift`, value: state.shifts[shiftindex] })
      } else {
        dispatch('setShiftDefault');
      }
    }
  },
  updateString({ commit }, { key, value }) {
    commit('updateField', { path: `params.${key}`, value })
  },
  updateSearchSettings({ dispatch, state }, searchParamsUsed) {
    searchParamsUsed = AxiosWrapper.copyAndDeleteNullProperties(searchParamsUsed)
    Object.keys(searchParamsUsed).forEach(key => {
      let value = searchParamsUsed[key]
      if (Object(state.params[key]) === state.params[key]) {
        dispatch('updateMultiSelect', { key, value })
      } else {
        dispatch('updateString', { key, value })
      }
    })
  },
  fetchQuestionnaire({ commit }, questionnaireGroupId) {
    commit('setQuestionnaires', []);
    commit('setInstructions', []);
    let searchParams = AxiosWrapper.getSearchParams();
    let jobRevisionId = AxiosWrapper.getNextPathNameElement("job_revisions");
    let jobId = AxiosWrapper.getNextPathNameElement("jobs");
    let apiUrl = "";
    let params = {
      ...searchParams,
      job_id: jobId,
      questionnaire_group_id: questionnaireGroupId
    };
    if (jobRevisionId) {
      apiUrl = `/data_api/jobs/${jobId}/job_revisions/${jobRevisionId}/checklist/control_chart_instructions`
      params.job_revision_id = jobRevisionId;
    } else {
      apiUrl = `/data_api/jobs/${jobId}/control_charts`
    }
    AxiosWrapper.get(apiUrl, { params }
    ).then(({ data }) => {
      commit('setQuestionnaires', data.questionnaires);
    });
  },
  fetchInstructions({ commit }, { questionnaireGroupId, questionnaireId }) {
    commit('setInstructions', []);
    let searchParams = AxiosWrapper.getSearchParams();
    let jobRevisionId = AxiosWrapper.getNextPathNameElement("job_revisions");
    let jobId = AxiosWrapper.getNextPathNameElement("jobs");
    let apiUrl = "";
    let params = {
      ...searchParams,
      job_id: jobId,
      questionnaire_group_id: questionnaireGroupId,
      questionnaire_id: questionnaireId
    };
    if (jobRevisionId) {
      apiUrl = `/data_api/jobs/${jobId}/job_revisions/${jobRevisionId}/checklist/control_chart_instructions`
      params.job_revision_id = jobRevisionId;
    } else {
      apiUrl = `/data_api/jobs/${jobId}/control_charts`
    }
    AxiosWrapper.get(apiUrl, { params }
    ).then(({ data }) => {
      const instructions = Object.entries(data.questionnaire_instructions).map(([key, value]) => {
        return { name: `${key} -> ${value}`, value: value };
      });
      // const instructions = data.questionnaire_instructions.map((item) => ({
      //   name: item,
      //   value: item
      // }));
      commit('setInstructions', instructions);
    });
  },
}