import Vue from "vue";
import { translate } from "~/assets/javascript/localization/Localization"
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  jobExpansionContainers: {},
  job: {},
  jobRevision: {},
  isUserCustomer: false,
  expansionTypes: [],
  jobRevisonId: AxiosWrapper.getNextPathNameElement("job_revisions"),
  savingInProgress: false,
})

export const state = getDefaultState

export const getters = {
  breadcrumbs(state) {
    return [
      {
        text: translate('job_revisions'),
        path: `/jobs/${state.job.id}/job_revisions`
      },
      {
        text: `${state.jobRevision['formatted_revision_date']} ${state.jobRevision['revision_number']}`,
        path: `/jobs/${state.job.id}/job_revisions/${state.jobRevision.id}`
      },
      { text: translate('expansions') }
    ]
  },
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestedData) {
    ({
      job_revision: state.jobRevision,
      job_expansion_containers: state.jobExpansionContainers,
      job: state.job,
      is_user_customer: state.isUserCustomer,
      expansion_types: state.expansionTypes,
    } = getRequestedData);
  },
  pushObjectToArray(state, { array, object }) {
    array.push(object)
  },
  setContainersExpansionType(state, { container, type }) {
    Vue.set(container, "job_expansion_type", type);
  },
  setContainersAttributes(state, { container, attributes }) {
    Vue.set(container, "job_expansions_attributes", attributes);
  },
  setContainersConstraintType(state, { container, type }) {
    Vue.set(container, "constraint_type", type);
  },
  setDestroyFlag(state, expansion) {
    Vue.set(expansion, "_destroy", true)
  },
  setJobExpansionType(state, { type, container }) {
    container.job_expansion_type = type
  },
  setExpansionId(state, { expansionId, container }) {
    container.job_expansion_id = expansionId
  },
  setExpansionIdOnConstraint(state, { expansionId, constraint }) {
    let index = state.jobExpansionContainers.findIndex(jc => jc.id === constraint.job_expansion_container_id)

    if (index > -1) {
      let containerIndex = state.jobExpansionContainers[index].job_expansions_attributes.findIndex(e => e.id === constraint.id)

      if (containerIndex > -1) {
        state.jobExpansionContainers[index].job_expansions_attributes[containerIndex].expansion_id = expansionId
      }
    }

    constraint.expansion_id = expansionId
  },
  updateField
}

export const actions = {
  async init({ state, commit, dispatch }, params) {
    commit('resetState')
    const response = await AxiosWrapper.get(`/data_api/audit/job_revisions/${state.jobRevisonId}/job_expansions`, { id: state.jobRevisonId })
    commit("init", response.data);
    dispatch('jobRevision/setJobRevision', response.data.job_revision, { root: true })
    dispatch("setContainersConstraintTypeToDisabledIfNotPresentInAvailableTypes");
    dispatch("setExpansionAndContraintTypeToEmptyString");
    dispatch("setJobExpansionsOnAllContainers");
  },
  setJobExpansionsOnAllContainers({ state, dispatch }) {
    state.jobExpansionContainers.forEach(selectedContainer => {
      dispatch("setJobExpansionsOn", selectedContainer);
    });
  },
  setContainersConstraintTypeToDisabledIfNotPresentInAvailableTypes({ state, commit }) {
    var availableTypesArray = []
    state.expansionTypes.forEach(typeObject => {
      commit("pushObjectToArray", {
        array: availableTypesArray,
        object: typeObject.type
      });
    });

    state.jobExpansionContainers.forEach(selectedContainer => {
      if (!availableTypesArray.includes(selectedContainer.job_expansion_type)) {
        commit("setContainersExpansionType", {
          container: selectedContainer,
          type: ""
        });
        commit("setContainersConstraintType", {
          container: selectedContainer,
          type: ""
        });
      }
    });
  },
  setExpansionAndContraintTypeToEmptyString({ state, commit, dispatch }) {
    state.jobExpansionContainers.forEach(selectedContainer => {
      if (selectedContainer.job_expansion_type == null) {
        commit("setContainersExpansionType", {
          container: selectedContainer,
          type: ""
        });
      }
      if (selectedContainer.constraint_type == null) {
        commit("setContainersConstraintType", {
          container: selectedContainer,
          type: ""
        });
      }
    });
  },
  setTypeAndExpansions({ dispatch, commit }, { type, container }) {
    commit("setJobExpansionType", { type, container })
    dispatch("setJobExpansionsOn", container)
  },
  setJobExpansionsOn({ dispatch }, container) {
    switch (container.job_expansion_type) {
      case "Constraint":
        dispatch("setCurrentJobExpansionsDependingOnType", { container, binLabel: "part_number_label" });
        break;
      case "SerialNumberConstraint":
        dispatch("setCurrentJobExpansionsDependingOnType", { container, binLabel: "serial_number_label" });
        break;
      case "LotNumberConstraint":
        dispatch("setCurrentJobExpansionsDependingOnType", { container, binLabel: "lot_number_label" });
        break;
      case "Other1Constraint":
        dispatch("setCurrentJobExpansionsDependingOnType", { container, binLabel: "other1_label" });
        break;
      case "Other2Constraint":
        dispatch("setCurrentJobExpansionsDependingOnType", { container, binLabel: "other2_label" });
        break;
      default:
        break;
    }
  },
  setCurrentJobExpansionsDependingOnType({ commit }, { container, binLabel }) {
    var selectedJobExpansions = container.job_expansions.find(function (element) {
      return element.bin_type == binLabel;
    });
    commit("setContainersAttributes", {
      container: container,
      attributes: selectedJobExpansions.expansions
    });
    commit("setContainersConstraintType", {
      container: container,
      type: binLabel
    });
  },
  deleteExpansionIfConstraintIsNull({ state, commit }) {
    state.jobExpansionContainers.forEach(container => {
      if (container.job_expansions_attributes) {
        container.job_expansions_attributes.forEach(expansion => {
          if (expansion.constraint == null) {
            commit("setDestroyFlag", expansion)
          }
        });
      }
    });
  },
  save({ state, dispatch }) {
    dispatch("deleteExpansionIfConstraintIsNull");

    let object = state.jobExpansionContainers.map(container => {
      let expansion_attributes = null
      if (container.job_expansions_attributes) {
        expansion_attributes = container.job_expansions_attributes.map(exp => {
          return {
            id: exp.id,
            constraint_type: exp.constraint_type,
            expansion_id: exp.expansion_id,
            ":_destroy": exp["_destroy"],
            type: exp.type
          }
        })
      }

      let cleanedContainer = {
        id: container.id,
        job_expansion_type: container.job_expansion_type,
        job_expansion_id: container.job_expansion_id,
        constraint_type: container.job_expansion_type == "" ? "" : container.constraint_type
      }

      if (expansion_attributes) {
        cleanedContainer['job_expansions_attributes'] = expansion_attributes
      }

      return cleanedContainer
    })

    var job_revision = {
      "job_expansion_containers_attributes": object
    }

    return this.$axios.patch(`/data_api/audit/job_revisions/${state.jobRevisonId}/job_expansions/update_all`,
      { job_revision, job_revision_id: state.jobRevisonId })
  }
}
