import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization'
AxiosWrapper.init();
import { getField, updateField } from 'vuex-map-fields';
import gtmEvents from "~/assets/javascript/gtmEvents"

const getDefaultState = () => ({
  generateCSVPath: null,
  suspectCodes: [],
  hasSearched: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")

    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
  },
  async search({ dispatch, rootState, rootGetters }) {
    gtmEvents.reportGenerated({
      type: "Suspect Stats"
    });

    let params = {
      ...ConfigHelper.mapParams(rootState.selects.params, ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate']
    }
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'SuspectStatsSearch', pathWithParams);
    dispatch("getCallForSearch", params)
  },
  async getCallForSearch({ commit, dispatch }, params) {
    AxiosWrapper.get(`/data_api/v0/defect_search/suspect_code_stats`, { params }).then(({ data }) => {
      commit("updateField", {
        path: "suspectCodes",
        value: data.suspect_codes
      });
      commit("updateField", {
        path: "hasSearched",
        value: true
      });

      commit("updateField", {
        path: "generateCSVPath",
        value: `data_api/v0/download_csv/suspect_code_statistic${AxiosWrapper.buildSearchUrl(params)}`
      });
      dispatch("contentWithHeader/update", labelValues => {
        delete labelValues[translate('job_revisions')]
        return labelValues
      }, { root: true })
    })
  }
}