import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization';

const getDefaultState = () => ({
  tabletRoles: {},
  showNewModal: false,
  // lessees:{}
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateTabletRole(state, { tabletRole }) {
    Vue.set(state.tabletRoles, tabletRole.role.id, tabletRole)
  },
  removeTabletRole(state, tabletRoleId) {
    Vue.delete(state.tabletRoles, tabletRoleId)
  },
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/v0/roles`)
      .then(response => {
        commit('updateField', { path: 'tabletRoles', value: { ...response.data } })
        // commit('updateField', { path: 'currentUser', value: response.data.current_user })
        // commit('updateField', { path: 'lessees', value: response.data.lessees })
      })
  },
  submitTabletRole({ commit, state }, { tabletRole }) {
    let id = tabletRole.role.id
    if (id) {
      AxiosWrapper.patch(
        `/data_api/v0/roles/${id}`,
        { tabletRole }
      ).then(response => {
        if (response.data.error) {
          this.$toast.error(response.data.error, { duration: 10000 })
        } else {
          commit('updateTabletRole', { tabletRole: response.data })
          if (tabletRole.role.id == 0) { commit('removeTabletRole', tabletRole.role.id) }
        }
      });
    } else {
      AxiosWrapper.post('/data_api/v0/roles', { tabletRole })
        .then(response => {
          commit('updateTabletRole', { tabletRole: response.data })
        })
    }

  },
  deleteTabletRole({ commit }, { tabletrole, vueInstance }) {
    AxiosWrapper.delete(`/data_api/v0/roles/${tabletrole.role.id}`)
      .then(response => {
        if (response.data === true) {
          commit('removeTabletRole', tabletrole.role.id)
        } else {
          vueInstance.$toast.error(response.data.error, { duration: 10000 })
        }
      }
      );
  },
}