import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import ModalHelper from "~/components/ModalHelper"
import { translate } from '~/assets/javascript/localization/Localization'

AxiosWrapper.init()

const getDefaultState = () => ({
  configuration: null,
  questionnaire: null,
  permissions: null,
  showRandomConfigModal: false,
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      configuration: state.configuration,
      questionnaire: state.questionnaire
    } = getRequestData)
  },
  setsShowRandomConfigModal(state, value) {
    state.showRandomConfigModal = value
  },
  updateField
}

export const getters = {
  getField,
  questionsCount(state) {
    if (state.questionnaire) return `/ ${state.questionnaire.questions_count}`
  },
  configurationId: (state) => (questionnaire) => {
    if (questionnaire["configuration"]) return questionnaire["configuration"]["questionnaire_configuration"]["id"]
    else return 'new'
  }
}

export const actions = {
  async openRandomConfigModal({ commit, getters }, questionnaire) {
    commit("resetState")

    const configurationId = getters.configurationId(questionnaire)

    AxiosWrapper.get(`/data_api/questionnaires/${questionnaire.id}/permutation/audit/questionnaire_configurations/${configurationId}/edit`).then(({ data }) => {
      commit("init", data)
      ModalHelper.addShowParamToUrl('show_random_config', questionnaire.id, 'Random Configuration')
      commit('setsShowRandomConfigModal', true)
    })
  },
  async closeRandomConfigModal({ commit }) {
    ModalHelper.removeShowParamFromUrl('show_random_config', 'Questionnaires')
    commit('setsShowRandomConfigModal', false)
  },
  async showRandomConfigComponentIfPresentInUrl({ dispatch }, questionnaires) {
    let params = AxiosWrapper.getSearchParams()
    if (Object.keys(params).includes('show_random_config')) {
      let questionnaire = questionnaires.find(q => q.id == parseInt(params['show_random_config']))
      dispatch('openRandomConfigModal', questionnaire)
    }
  },
  validateAndChangeSetSize({ commit, state }) {
    if (state.configuration.set_size > state.questionnaire.questions_count) {
      commit('updateField', { path: "configuration.set_size", value: state.questionnaire.questions_count });
    } else if (state.configuration.set_size < 0) {
      commit('updateField', { path: "configuration.set_size", value: 0 });
    }
  },
  // save({ commit, state }, vueRouter) {
  //   commit('updateField', { path: "configuration.disabled", value: false})
  //   ModalHelper.removeShowParamFromUrl('show_random_config', 'Questionnaires')
  //   AxiosWrapper.postOrPatch(`/data_api/questionnaires/${state.questionnaire.id}/permutation/audit/questionnaire_configurations`, {
  //     permutation_audit_questionnaire_configuration: state.configuration
  //   }).then(vueRouter.go())
  // }
  async save({ commit, state }, vueRouter) {
    commit('updateField', { path: "configuration.disabled", value: false })
    ModalHelper.removeShowParamFromUrl('show_random_config', 'Questionnaires')
    try {
      await AxiosWrapper.postOrPatch(`/data_api/questionnaires/${state.questionnaire.id}/permutation/audit/questionnaire_configurations`, {
        permutation_audit_questionnaire_configuration: state.configuration
      });
      vueRouter.go()
    } catch (error) {
      this.$toast.error(translate("there_was_a_problem"), { duration: 1000 })
    }

  }
}